import React from 'react';

const MySVGComponent = ({style, size, icon, color='#fff'}) => {

    if (icon === 'add_account') {
        return (
            <svg className={style} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill=""/>
                <path d="M9 12H15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 9L12 15" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke={color} strokeWidth="2"/>
            </svg>
        );
    }

    if (icon === 'settings') {
        return (
            <svg fill={color} width={size} height={size} viewBox="0 0 16 16" id="sliders-16px" xmlns="http://www.w3.org/2000/svg">
                <path id="Path_37" data-name="Path 37" d="M568-384v-1h1.5a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5Zm-13.5,0H566v1.5a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-4a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v1.5H554.5a.5.5,0,0,0-.5.5A.5.5,0,0,0,554.5-384Zm15,4.5H557v1h12.5a.5.5,0,0,0,.5-.5A.5.5,0,0,0,569.5-379.5Zm0,5.5H564v1h5.5a.5.5,0,0,0,.5-.5A.5.5,0,0,0,569.5-374Zm-6.5-1v-.5a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v1.5h-7.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5H562v1.5a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5Zm-7-6a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v1.5h-.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h.5v1.5a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5Z" transform="translate(-554 387)"/>
            </svg>
        );
    }

    if (icon === 'payment') {
        return (
            <svg fill={color} width={size} height={size} viewBox="0 0 16 16" id="plus-circle-16px" xmlns="http://www.w3.org/2000/svg">
                <path id="Path_59" data-name="Path 59" d="M-13,0a8.009,8.009,0,0,0-8,8,8.009,8.009,0,0,0,8,8A8.009,8.009,0,0,0-5,8,8.009,8.009,0,0,0-13,0Zm0,15a7.008,7.008,0,0,1-7-7,7.008,7.008,0,0,1,7-7A7.008,7.008,0,0,1-6,8,7.008,7.008,0,0,1-13,15Zm5-7a.5.5,0,0,1-.5.5h-4v4a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5v-4h-4A.5.5,0,0,1-18,8a.5.5,0,0,1,.5-.5h4v-4A.5.5,0,0,1-13,3a.5.5,0,0,1,.5.5v4h4A.5.5,0,0,1-8,8Z" transform="translate(21)"/>
            </svg>
        );
    }
    if (icon === 'security') {
        return (
            <svg fill={color} width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <g id="_x31_9">
                    <path
                        d="m15.0341797 7c0-2.7568359-2.2431641-5-5-5s-5 2.2431641-5 5 2.2431641 5 5 5 5-2.2431641 5-5z"></path>
                    <path
                        d="m29.4960938 16.1318359-7-4c-.3066406-.1757813-.6855469-.1757813-.9921875 0l-7 4c-.3115235.1777344-.5039063.5097657-.5039063.8681641 0 4.484375 1.9892578 8.453125 5.7509766 11.4755859l1.6220703 1.3037109c.1835937.147461.4052734.2207032.6269531.2207032s.4433594-.0732422.6269531-.2207031l1.6210938-1.3037109c3.7626953-3.022461 5.7519531-6.991211 5.7519531-11.475586 0-.3583984-.1923828-.6904297-.5039062-.8681641zm-3.6962891 2.4677735-3 4c-.1738281.2324219-.4394531.3769531-.7285156.3974609-.0244141.0019531-.0478516.0029297-.0712891.0029297-.2646484 0-.5185547-.1044922-.7070313-.2929688l-2-2c-.390625-.390625-.390625-1.0234375 0-1.4140625s1.0234375-.390625 1.4140625 0l1.1845703 1.1845703 2.3085938-3.0771484c.3310547-.4423828.9580078-.5302734 1.3994141-.2001953.4423828.3310547.5322265.9580078.2001953 1.3994141z"></path>
                    <path
                        d="m14.0087891 15.2636719 2.7266235-1.5582886c-.9174194-1.0369263-2.244812-1.7053833-3.7383423-1.7053833h-2.9628906-3.03125c-2.7587891 0-5.0029297 2.2353516-5.0029297 4.9833984v7.0166016c0 .5527344.4472656 1 1 1h12.295105c-1.5049439-2.3944702-2.295105-5.09198-2.295105-8 0-.7167969.3867188-1.3818359 1.0087891-1.7363281z"></path>
                </g>
            </svg>
        );
    }


};

export default MySVGComponent;